import { useCallback } from "react";
import ActivityService from "../../../../services/ActivityService";
import CompanyService from "../../../../services/CompanyService";
import FloatingPanelService from "../../../shared/FloatingPanel";
import { TranslationService } from "../../../../services/TranslationService";
import { EmailCompose } from "../../components/EmailCompose";
import { ActivityItemTemplate, deleteActivityAction } from "../ActivityHistory";
import { ActivityListResponse } from "../entities/AcitivityListResponse";
import { EmailEdit } from "./EmailEdit";
import { ActivityHistoryType } from "../../../shared/enums";
import { ToastService } from "../../../shared/bootstrap/Toast";
import ModalService from "../../../shared/bootstrap/Modal";
import { addIf } from "../../../../utils/Utils";

export const ActivityEmail = ({ data, reload }: { data: ActivityListResponse.Item, reload: () => void }) => {
    const { translate } = TranslationService;

    const onReadMoreClicked = async (id: number) => {
        const result = await ActivityService.getEmailContent({ id: id.toString(), noheader: "0" });
        if (result instanceof Error) {
            ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        ModalService.showDefaultModal({
            message: () => <div style={{ overflow: "auto" }} dangerouslySetInnerHTML={{ __html: result.content }}></div>,
            title: translate.Email,
            size: "modal-xl",
        });
    };

    const openEmailModal = (subject: string, to?: string, groupId?: string, groupName?: string) => {
        const onSubmit = () => reload();
        FloatingPanelService.showPanel({
            title: translate.EmailToClient,
            children:
                <EmailCompose
                    personId={data.PersonId.toString()}
                    defaultSubject={subject}
                    defaultTo={to}
                    defaultGroupId={groupId}
                    defaultGroupLabel={groupName}
                    prevMessageId={data.ID.toString()}
                    onSubmit={onSubmit} />,
            width: 900,
            height: 669,
        });
    };

    const downloadIo = () => {
        ActivityService.exportMessageIo(data.ID, data.IOCount);
    };

    const reply = () => {
        openEmailModal("RE: " + data.Subject, data.From, data.groupId, data.groupName);
    };

    const replyAll = () => {
        openEmailModal("RE: " + data.Subject, data.From! + "," + data.To, data.groupId, data.groupName);
    };

    const forward = () => {
        openEmailModal("FW: " + data.Subject, undefined, data.groupId, data.groupName);
    };

    const edit = useCallback(async () => {
        const emailRequest = await ActivityService.get(data.ID);
        if (emailRequest instanceof Error) {
            ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
            return;
        }
        FloatingPanelService.showPanel({
            title: translate.EmailToClient,
            children: <EmailEdit email={emailRequest.item} onSubmit={reload} />,
            width: 600,
            height: 569,
        });
    }, [data, reload, translate.EmailToClient, translate.ErrorProcessingRequest]);

    const replyAction = () => ({
        icon: "fa-reply",
        onClick: reply,
        tooltip: TranslationService.translate.Reply,
    });

    const replyAllAction = () => ({
        icon: "fa-reply-all",
        onClick: replyAll,
        tooltip: TranslationService.translate.ReplyAll,
    });

    const forwardAction = () => ({
        icon: "fa-arrow-alt-right",
        onClick: forward,
        tooltip: TranslationService.translate.Foward,
    });

    const emailAction = () => ({
        icon: "fa-pen",
        onClick: edit,
        tooltip: TranslationService.translate.Edit,
    });

    const actions = [
        ...addIf(data.hasIOs, {
            icon: "fa-download",
            onClick: downloadIo,
            tooltip: TranslationService.translate.DownloadAttachedInvoiceList,
        }),
        replyAction(),
        replyAllAction(),
        forwardAction(),
        emailAction(),
        deleteActivityAction(data.ID, translate.EmailDeleteTitle, translate.EmailDeleteMessage, reload),
    ];

    const content = <div>
        <div style={{ overflow: "auto" }} dangerouslySetInnerHTML={{ __html: data.Content }}></div>
        {data.HasMoreContent && <button className="btn btn-intiza ps-0 mt-1 ctaViewCommentsRead" onClick={() => onReadMoreClicked(data.ID)}>{translate.ReadMore}</button>}
    </div>;

    return (
        <ActivityItemTemplate data={data} type={ActivityHistoryType.email} date={new Date(data.date)} creator={data?.From || data.Username} content={content} subject={data.Subject} actions={CompanyService.canDo("editmail") ? actions : []} to={data.To} />
    );
};