import { SortDirection } from "../../shared/entities/Sort";

export class ClientListRequest {
    constructor(public segmentid: null | number = null) {
    }
    page: number = 0;
    sortColumn: string = "amount";
    descending: SortDirection = SortDirection.Descending;
    text: string | null = null;
    impid: null = null;
    actionsentid: null = null;
    reason: null = null;
    pagesize: number = 20;
    filter: string | null = null;
}

export type ClientListRequestExport = { expectedRows: number } & Partial<ClientListRequest>