import { FloatingPanelFooter } from '../../shared/components/FloatingPanelFooter';
import { TranslationService } from '../../../services/TranslationService';
import { TextAreaBigEditor } from '../../shared/components/Editors';
import TextInput from './TextInput';

export const SendMessageCompose = () => {
  return (
    <>
      <div className="tabBody">
        <div className="row mb-2 g-0 ">
          <div className="col-2 pe-2">
            <label className="form-label mb-0">{TranslationService.translate.Mail}</label></div>
          <div className="col">
            <TextInput />
          </div>
        </div>

        <div className="row mb-2 g-0 ">
          <div className="col-2 pe-2">
            <label className="form-label">{TranslationService.translate.Message}</label>
          </div>
          <div className="col">
            <TextAreaBigEditor extraStyle={{ height: 200 }} onChange={() => { }} />
          </div>
        </div>

      </div>
      <FloatingPanelFooter>
        <button className='btn btn-primary me-3'>
          {TranslationService.translate.Send}
        </button>
      </FloatingPanelFooter>
    </>
  )
}
