import React, { useCallback, useState } from "react";
import ClientService from "../../../services/ClientService";
import { ClientListRequest } from "../entities/ClientListRequest";
import { ClientListResponse } from "../entities/ClientListResponse";
import TableContext, { TableContextValues } from "../../task/TableContext";
import { applyFiltersHelper, calculatePageCount, setCurrentPageHelper, setPageSizeHelper, sortHelper, useEndpointData } from "../../../utils/TableUtils";

export class ClientTableContextValues extends TableContextValues<ClientListResponse, { extraFilters: string[] }> {
    max: number = 0;
    request: ClientListRequest = new ClientListRequest();
    setRequest: (request: ClientListRequest) => void = () => { };
}

const ClientTableProvider = ({ children, prevRequest = undefined, segmentId = null }: React.PropsWithChildren<{ prevRequest?: ClientListRequest, segmentId?: number | null }>) => {
    const [request, setRequest] = useState(prevRequest ?? new ClientListRequest(segmentId));
    const endpoint = useCallback(() => ClientService.getList(request), [request]);
    const { loading, error, response, reload } = useEndpointData(endpoint);
    const pageCount = calculatePageCount(response);
    const { sort, setSortColumn } = sortHelper(request, setRequest);
    const applySearch = (keyword: any) => {
        setRequest({ ...request, text: keyword })
    };
    const value: ClientTableContextValues =
    {
        loading,
        error,
        response,
        setSortColumn,
        currentPage: request.page,
        setCurrentPage: setCurrentPageHelper(setRequest, pageCount),
        pageCount: pageCount,
        applyFilters: applyFiltersHelper(setRequest),
        applySearch,
        reload,
        sort,
        max: response?.max ?? 0,
        pageSize: request.pagesize,
        setPageSize: setPageSizeHelper(setRequest),
        request,
        setRequest,
    }

    return (
        <TableContext.Provider value={value}>
            {children}
        </TableContext.Provider>
    )
}

export default ClientTableProvider; 