import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { TranslationService } from "../../services/TranslationService";
import PortalClientService, { PortalClientDataRequest, PortalClientResponse, PortalIoStatusesDataResponse } from "../../services/PortalClientService";
import PortalClientTableProvider, { PortalClientTableContextValues } from "./providers/PortalClientTableProvider";
import TableContext from "../task/TableContext";
import { PortalHeader } from "./components/PortalHeader";
import AdvancedFilters, { AdvancedFiltersProvider } from "../shared/components/AdvancedFilters";
import { ButtonTooltipIcon } from "../shared/components/ButtonTooltipIcon";
import FilterService, { FilterCollection } from "../../services/FilterService";
import ExportPortalService from "../../services/ExportPortalService";
import Dropdown from "../shared/components/Dropdown";
import { capitalize, uncapitalize } from "../../utils/FormatUtils";
import CompanyService from "../../services/CompanyService";
import { CompanyAuth } from "../../entities/login/CompanyAuth";
import MenuButton from "../shared/components/MenuButton";
import { ToastService } from "../shared/bootstrap/Toast";
import FloatingPanelService from "../shared/FloatingPanel";
import { SendMessageCompose } from "./components/SendMessageCompose";
import { PortalInvoicesTable } from "./components/PortalInvoicesTable";
import { SortDirection } from "../shared/entities/Sort";
import './styles.css';

const replaceTranslate = (text: string, fieldName?: string, fieldsName?: string) => {
  if (fieldName && fieldsName) {
    return text
      .replaceAll("Cliente", capitalize(fieldName))
      .replaceAll("Clientes", capitalize(fieldsName))
      .replaceAll("cliente", uncapitalize(fieldName))
      .replaceAll("clientes", uncapitalize(fieldsName))
  }
  return text;
}

export const PortalClientContainer = () => {
  const [dataClient, setDataClient] = useState<PortalClientResponse>();
  const [dataClientAdditionals, setDataClientAdditionals] = useState<PortalClientResponse>();
  const [, setDataIoStatuses] = useState<PortalIoStatusesDataResponse>();
  const history = useHistory()
  const tokenId = new URLSearchParams(history.location.search).get("t") ?? "";
  const { currentLanguage } = TranslationService;

  useEffect(() => {
    const loadData = async (token: string) => {
      const result = await PortalClientService.getClientData(token);
      if (result instanceof Error) {
        console.error({ result })
        return;
      } else if (result?.data) {
        setDataClient(result.data);
      }
    };

    const loadDataAdditionals = async (token: string) => {
      const result = await PortalClientService.getAdditionals(token);
      if (result instanceof Error) {
        console.error({ result })
        return;
      } else if (result?.data) {
        setDataClientAdditionals(result.data);
        CompanyService.setCompanyAuth({
          additionalDefinitions: result.data as unknown as CompanyAuth.AdditionalDefinition[],
          companies: [],
          currencies: [],
          users: [],
          configItems: [],
          iostatus: [],
          activityTypes: [],
          reportExports: [],
          tags: [],
          segments: [],
          transactionTypes: [],
          emailTemplates: [],
          emailFrames: [],
          storedFilters: [],
          companyGuid: "",
          companyName: "",
          defaultCurrencySymbol: "",
          HidePriority: false,
          IOGroup: [],
          defaultCurrencyId: 0,
          companyToken: "",
          refreshToken: "",
          block: null,
          expire: "",
          Production: false,
          AdminId: null,
          personSortedFields: "",
          ioSortedFields: "",
          showSMS: false,
          groupName: "",
          PersonKey: 0,
          dashBoardPanelId: null,
          EnabledSegmentPriorities: false,
          TotalPriorities: 0,
          NormalIOStatusName: "",
          userCount: 0,
          accountUserCount: 0,
          accountPersonCount: 0,
          accountReadonlyUserCount: 0,
          OrderMailBy: "",
          GroupMailBy: "",
          OrderMailByOrderDesc: "",
          GraphScales: [],
          HasReceipt: false,
          logoUrl: "",
          ActionLanguage: "",
          Languages: [],
          ShowCents: false,
          excludedFilterFields: [],
          Whatsapp: false,
          customerFieldName: "",
          customersFieldName: "",
          versionAllowed: null
        })
        CompanyService.getAdditionalDefinitions()
      }
    };

    const loadIoStatuses = async (token: string) => {
      const result = await PortalClientService.getIoStatuses(token);
      if (result instanceof Error) {
        console.error({ result })
        return;
      } else if (result) {
        setDataIoStatuses(result);
      }
    };

    if (tokenId) {
      loadData(tokenId);
      loadDataAdditionals(tokenId);
      loadIoStatuses(tokenId);
    } else {
      history.push(`/${currentLanguage}/`)
    }
  }, [currentLanguage, history, tokenId])

  return (
    <PortalClientTableProvider token={tokenId} prevRequest={new PortalClientDataRequest()}>
      {(dataClientAdditionals && dataClient) && <PortalClient dataClient={dataClient} tokenId={tokenId} />}
    </PortalClientTableProvider>
  )
}

const PortalClient = ({ dataClient, tokenId }: { dataClient?: PortalClientResponse, tokenId: string }) => {
  const [otherFilters, setOtherFilters] = useState(false)
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const { response, setRequest, request, loading } = useContext(TableContext) as PortalClientTableContextValues;
  const hasPaymentMethod = dataClient?.paymentMethod || false;
  const togglePanel = () => setIsPanelOpen(!isPanelOpen);

  const [requestUpdated, setRequestUpdated] = useState(request)
  const [quickFilter, setQuickFilter] = useState<number | null>(null)

  const filterItems = useMemo(() => {
    let filterItems = [
      { text: TranslationService.translate.Select, key: -1, value: -1 },
      { text: 'Facturas Vencidas', key: 0, value: 0 },
      { text: 'Todas las facturas pendientes', key: 1, value: 1 },
      { text: 'Otros Filtros', key: 3, value: 3 },
    ];

    if (response?.customerportalshowhistory) {
      filterItems.push({ text: 'Todas las facturas', key: 2, value: 2 });
    }
    return filterItems;
  }, [response?.customerportalshowhistory]);

  const exportInvoiceList = async () => {
    await ExportPortalService.requestExport("/customer/exportiolist", {
      descending: request.descending || SortDirection.Descending,
      filter: request.filter || '',
      quickFilter: request.quickFilter,
    }, 1, 'customer_list.xlsx', tokenId);
  };

  const actionSendMessage = (value: string) => {
    if (!selectedRows.length) {
      ToastService.showToast(TranslationService.translate.SendMessageAlertWithoutMsg, undefined, "danger");
      return;
    }

    FloatingPanelService.showPanel({
      title: TranslationService.translate.SendMessage,
      children: <SendMessageCompose />,
      width: 800,
      height: 450,
      position: 'center'
    });
  }

  const selectGroupInvoice = useCallback((filters: string[]) => {
    setRequest({ ...requestUpdated, quickFilter, filter: FilterService.GetExtraFiltersRequestString(filters) || '' });
  }, [quickFilter, requestUpdated, setRequest])

  useEffect(() => {
    setRequestUpdated(prev => ({ ...prev, quickFilter }))
    setOtherFilters(quickFilter === 3);

    if (quickFilter !== null && quickFilter >= 0 && quickFilter !== 3) {
      setRequest({ ...requestUpdated, quickFilter });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickFilter])


  return (
    <>
      <PortalHeader
        logo={dataClient?.urlLogo}
        personName={dataClient?.personName || "Cliente"}
      />

      <div
        className={"d-flex flex-column flex-grow-1 bg-white"}
        style={{ backgroundColor: "#86c8ea", minHeight: "calc(100vh - 179px)" }}
      >
        <div className="bg-white">
          {/* <PortalPanel {...{ isPanelOpen, togglePanel }} /> */}

          <ul className="sticky-to-top bg-white nav nav-tabs px-4 pt-2" id="pills-tab" role="tablist">
            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">
              Facturas
            </button>
            <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">
              Mis datos
            </button>
            {hasPaymentMethod && <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">
              Formas de pago
            </button>}
          </ul>

          <div className="tab-content p-0" id="pills-tabContent">

            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
              <AdvancedFiltersProvider>

                <div className="d-flex flex-wrap pt-2 px-2 px-md-4">
                  {/* En tamaño md debe ocupar una columna completa (col-1 en md), en otros tamaños será col-auto */}
                  <div className="col-2 col-md-auto">
                    <ButtonTooltipIcon
                      title={TranslationService.translate.export}
                      icon="fa-light fa-arrow-down-to-line text-granite-gray ms-1"
                      onClick={exportInvoiceList}
                      isLink={false}
                    />
                  </div>

                  {/* En tamaño md debe ocupar 4 columnas y alinearse a la izquierda; en otros tamaños será col-10 y alineado a la derecha */}
                  <div className="col-md-4 col-10 ms-md-0 ms-auto">
                    <Dropdown items={filterItems} onChange={setQuickFilter} defaultValue={request?.quickFilter || undefined} />
                  </div>

                  <div className="col-2" />
                  {/* En todos los tamaños de pantalla debe tener la misma medida que el Dropdown y alinearse a la derecha */}
                  {response?.customerMessages &&
                    <div className="col-md-4 col-10 ms-md-auto">
                      <MenuButton
                        text={TranslationService.translate.SendMessage}
                        actions={[
                          { text: 'Comprometer pago', action: () => actionSendMessage("1") },
                          { text: 'Presentar disputa', action: () => actionSendMessage("2") },
                          { text: 'Otro', action: () => actionSendMessage("3") }
                        ]}
                        showTitle
                      />
                    </div>}
                </div>

                {(otherFilters) &&
                  <div className="pt-0">
                    <AdvancedFilters page={FilterCollection.PortalFixed} onFilterApply={selectGroupInvoice} />
                  </div>}

                {response &&
                  <PortalInvoicesTable
                    {...{
                      response,
                      setRequest,
                      request,
                      togglePanel,
                      otherFilters,
                      setOtherFilters,
                      tokenId,
                      selectedRows,
                      setSelectedRows,
                      selectAll,
                      setSelectAll,
                    }}
                    isLoading={(!dataClient || loading)} />
                }
              </AdvancedFiltersProvider>
            </div>

            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
              <div className="container mt-md-4 mt-2">
                <div className="row">

                  <div className="col-md-8">
                    <h2 className="h4">{replaceTranslate(TranslationService.translate.CustomerInfo, dataClient?.customerFieldName, dataClient?.customersFieldName)}</h2>
                    {dataClient?.personName &&
                      <div className="row mb-2">
                        <div className="col-4"><strong>{TranslationService.translate.Name}:</strong></div>
                        <div className="col-8">{dataClient.personName}</div>
                      </div>}

                    {dataClient?.clientInfo &&
                      <>
                        {
                          Object.keys(dataClient.clientInfo)
                            .filter(el => el !== 'Hipervínculo')
                            .map((el) =>
                              <div className="row mb-2">
                                <div className="col-4"><strong>{el}:</strong></div>
                                <div className="col-8">{dataClient.clientInfo[el]}</div>
                              </div>)
                        }
                        {
                          dataClient.clientInfo?.Hipervínculo &&
                          <div className="row mb-2">
                            <div className="col-4"><strong>{TranslationService.translate.Hiperlink}:</strong></div>
                            <div className="col-8">
                              <a href={dataClient?.clientInfo.Hipervínculo} target="_blank" rel="noopener noreferrer">Ver Cliente</a>
                            </div>
                          </div>
                        }
                      </>
                    }

                  </div>
                  <div className="col-md-4 text-center my-2" />
                </div>
              </div>
            </div>

            {hasPaymentMethod && <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
              <div className="container mt-md-4 mt-2">
                <div className="row">
                  <div className="col-md-8">
                    <h2 className="h4">{TranslationService.translate.PaymentMethod}</h2>
                    <div className="row mb-2">
                      {dataClient?.paymentMethod ?
                        <div className="col-12" dangerouslySetInnerHTML={{ __html: dataClient.paymentMethod }} /> :
                        <div className="col-12">{TranslationService.translate.NoDataToShow}</div>}
                    </div>
                  </div>
                  <div className="col-md-4 text-center my-2">
                  </div>
                </div>
              </div>
            </div>}

          </div>
        </div>
      </div>
    </>
  )
}


export default PortalClient;
